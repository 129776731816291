.price-list {
    padding-top: 70px;

    &__container {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
    }

    &__wrapper {
        display: flex;
        justify-content: space-around;
        flex-flow: row wrap;
    }

    &__table {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &__title {
            font-size: 25px;
            font-weight: 700;
            text-align: center;
            line-height: 30px;
            margin-bottom: -40px;
            @media (max-width:360px) {
                font-size: 22px;
              }
        }
    }
}