.work {
    margin-top: 20px;

    &__header-block {
        margin: 30px 0px 65px 0px;
    }


    &__column {
        display: flex;
        justify-content: space-around;
        flex-flow: row wrap;
    }

    &__item {
        width: 358px;
        height: 560px;
        color:  black;
        border: 1px solid rgb(58, 58, 58) ;
        box-shadow: 0 0 5px rgba(0,0,0,0.5); 
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        @media (max-width:361px) {
            width: 320px;
          }
    }

    &__icon {
        width: 356px;
        height: 225px;
        border-bottom: 1px solid rgb(58, 58, 58) ;
        margin-bottom: 25px;

        @media (max-width:361px) {
            width: 320px;
          }
        
    }

    &__title {
        height: 230px;
        overflow: hidden;
        padding: 0px 22px 22px 22px;
        margin-bottom: 15px;
    }

    &__text {
        font-size: 16.4px;
        line-height: 30px;
        text-transform: uppercase;
        font-weight: 900;
        margin-bottom: 15px;
    }

    &__subtext {
        display: flex;
        justify-content: space-between;
        list-style-type: disc;
        font-size: 19px;
        line-height: 30px;
        font-weight: 400;
        text-align: justify;
        @media (max-width:450px) {
            font-size: 18px;
            line-height: 26px;
        }

        @media (max-width:380px) {
            font-size: 17px;
            line-height: 25px;
        }
    }

}
