.kanalizaciya {
    padding-top: 70px;
    text-align: center;
    &__tablet {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 8px;
    }
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-flow: row wrap;
        &__left {
            &__title {
                font-size: 23px;
                line-height: 30px;
                text-transform: uppercase;
                font-weight: 900;
                margin-bottom: 15px;
            }
            &__subtitle {
                list-style-type: disc;
                font-size: 20px;
                line-height: 30px;
                font-weight: 500;
            }
        }

        &__right {
            width: 300px;
            height: 300px;
        }
    }


    &__block {
        background-color: rgb(248, 248, 248);
        border-radius: 2%;
        &__header {
            display: flex;
            justify-content: space-around;
            flex-flow: row wrap;
            align-items: center;
            padding: 20px 0 20px 0;
            &__image {
                width: 300px;
                height: 200px;
            }
            &__title {
                display: flex;
                flex-direction: column;
                line-height: 90px;
                margin-top: 10px;
                @media (max-width:1023px) {
                    line-height: 40px;
                }
                @media (max-width:550px) {
                    line-height: 30px;
                }
                @media (max-width:450px) {
                    line-height: 22px;
                }
                &__text {
                    font-size: 23px;
                    text-transform: uppercase;
                    font-weight: 700;
                    @media (max-width:712px) {
                        font-size: 20px;
                    }
                    @media (max-width:550px) {
                        font-size: 16px;
                    }
                    @media (max-width:400px) {
                        font-size: 14px;
                    }
                }
                &__subtext {
                    font-size: 23px;
                    text-transform: uppercase;
                    font-weight: 700;
                    @media (max-width:712px) {
                        font-size: 18px;
                    }
                    @media (max-width:550px) {
                        font-size: 16px;
                    }
                    @media (max-width:400px) {
                        font-size: 13px;
                    }
                }
            }
        }

        &__table {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 20px;
        }
    }
}











.tb_header {
    max-width: 100%;
    padding: 100px 0 100px 0;
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    text-align: center;
        @media (max-width:617px) {
          font-size: 15px;
      }
  
          @media (max-width:450px) {
          font-size: 13px;
    }
  
                @media (max-width:355px) {
          font-size: 12px;
      }
  
  
  }
  
  
  .soflex1 div div
  {
  
    background: #efefef;
    border: 1px solid #7F7F7F;
    padding: 9px 20px;
    @media (max-width:492px) {
      padding: 9px 10px;
    }
  
      @media (max-width:450px) {
      padding: 3px 5px;
    }
  
          @media (max-width:370px) {
      padding: 3px 1px;
      }
  
              @media (max-width:355px) {
      padding: 1px 1px;
      }
  
  }
  
  
  .soflex1
  {
    flex-direction: row;
    display: flex;
  
  
    &__title {
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
        text-align: justify;
  
        @media (max-width:617px) {
          font-size: 16px;
      }
  
            @media (max-width:492px) {
          font-size: 15px;
      }
  
        @media (max-width:450px) {
          font-size: 12px;
      }
  
                    @media (max-width:355px) {
          font-size: 11px;
      }
  
                        @media (max-width:318px) {
          font-size: 9px;
      }
    }
  }