.about {
    &__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-weight: 500;
        font-size: 25px;
        line-height: 40px;
        // text-transform: uppercase;
        letter-spacing: 2.15px;

        &__title {
            margin: 10px 0 60px 0;

            @media (max-width:460px) {
              font-size: 23px;
            }
 
            @media (max-width:414px) {
              font-size: 18px;
            }
        
            @media (max-width:411px) {
              font-size: 18px;
            }
        
            @media (max-width:375px) {
              font-size: 20px;
            }
        
            @media (max-width:360px) {
                font-size: 19px;
            }
        
            @media (max-width:320px) {
              font-size: 19px;
            }
        }

        &__subtitle {
            margin-bottom: 50px;

            @media (max-width:460px) {
              font-size: 23px;
            }

            @media (max-width:414px) {
              font-size: 18px;
            }
        
            @media (max-width:411px) {
              font-size: 18px;
            }
        
            @media (max-width:375px) {
              font-size: 20px;
            }
        
            @media (max-width:360px) {
                font-size: 19px;
            }
        
            @media (max-width:320px) {
              font-size: 19px;
            }
        }
    }

    &__body {
        &__title {
            display: flex;
            justify-content: center;
            text-align: center;
            font-weight: 700;
            font-size: 23px;
            text-transform: uppercase;
            letter-spacing: 3.15px;
            position: relative;
            padding: 50px 0px 20px 0px;

            @media (max-width:500px) {
                font-size: 19px;
              }

              @media (max-width:414px) {
                font-size: 18px;
              }
          
              @media (max-width:411px) {
                font-size: 18px;
              }
          
              @media (max-width:375px) {
                font-size: 17px;
              }
          
              @media (max-width:360px) {
                  font-size: 17px;
              }
          
              @media (max-width:320px) {
                font-size: 17px;
              }

              



              &:after {
                content: '';
                position: absolute;
                width: 150px;
                bottom: 0;
                height: 2px;
                left: 50%;
                background-color: #000;
                margin: 0px 0px 0px -80px;
                @media (max-width:500px) {
                  width: 50%;
                }
              }
        }

    }

    &__row {
        display: flex;
        justify-content: space-around;
        flex-flow: row wrap;
        margin-top: 30px;
    }

    &__block {
        margin-left: 12px;
        height: 80px;
        width: 400px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: center;


        
    }

    &__text {
        font-weight: 700;
        font-size: 20px;
        text-transform: uppercase;
        letter-spacing: 1.15px;
        @media (max-width:500px) {
          font-size: 18px;
        }
        @media (max-width:380px) {
          font-size: 15px;
        }
    }
}
