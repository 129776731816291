.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    padding-top: 100px;
    z-index: 2;
}
 
.modal-body {
    padding: 20px;
    width: 500px;
    border-radius: 5px;
    background-color: #ffff;
    height: 400px;
}

input[type=text], select, textarea {
    background-color: rgb(209, 209, 209);
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}



.close {
    display: flex;
    justify-content: flex-end;
    color: #000;
    font-size: 35px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: red;
    cursor: pointer;
}


.contacts__form-row {
    padding: 5px;
    border: 1px solid rgb(250, 250, 250);
    border-radius: 4px;
    background-color: rgb(250, 250, 250);
}


 

        .contacts__form-header-title {
            font-size: 25px;
            font-weight: 600;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-bottom: 60px;
    
        }
        
        .contacts__form-header-text {
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 1px;
        }
