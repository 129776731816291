.dry-detail {
    padding-top: 70px;

    &__wrapper {
        display: flex;
        flex-direction: column;
        // align-items: center;
    }


    &__header {
        &__title {
            display: flex;
            flex-direction: column;
            align-items: center;
            &__text {
                font-size: 23px;
                line-height: 30px;

                font-weight: 700;
                margin-bottom: 15px;
            }

            &__subtext {
                font-size: 17px;
                line-height: 30px;
                font-weight: 400;
            }
        }
    }

    &__table {
        margin-top: -80px;
        display: flex;
        justify-content: center;
    }

    &__footer {
        margin-top: -80px;
        display: flex;
        flex-direction: column;

        &__top {
            display: flex;
            justify-content: space-around;
            flex-flow: row wrap;

            &__left {
                width: 500px;
                height: 300px;
                @media (max-width:500px) {
                    width: 300px;
                    height: 200px;
                }
            }

            &__right {
                margin-top: 70px;
                font-size: 25px;
                line-height: 30px;
                font-weight: 600;
            }
        }

        &__bottom {
            display: flex;
            justify-content: center;
            margin: 50px 0 50px 0;
        }
    }
}