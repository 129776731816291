.footer {
    color: #BBBBBB;
    background-color: #282c34;
 
    &__wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        padding: 40px 0 30px 0;
        
    }





    &__logo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 350px;
        height: 300px;
        margin-bottom: 20px;

        &__img {
            width: 200px;
            height: 200px;
        }

        &__title {

            &__text {
                margin-top: 20px;
                font-size: 15px;
                font-weight: 400;
                
            }

            &__subtext {
                margin-top: 20px;
                font-size: 15px;
                font-weight: 400;
            }
        }
    }





    &__contacts {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 350px;
        height: 300px;
        margin-bottom: 20px;
        
        &__title {
            font-size: 25px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 5px;
        }

        &__text {
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 1px;
            line-height: 50px;

        }

        &__phone {
            color: white;
            font-weight: 500;
            font-size: 17px;
            text-decoration: none;
            text-transform: uppercase;
        }
    }



    &__form {
        width: 350px;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 40px;
    }

    &__form-header {
        &__title {
            font-size: 25px;
            font-weight: 600;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-bottom: 60px;

        }
        &__text {
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 1px;
        }
    }

    &__form-row {
        background-color: #282c34;

    }

    &__btn {
        background-color: #3d3d3d;
        color: white;
        text-transform: uppercase;
        padding: 12px 20px;
        border: 1px solid #BBBBBB;
        border-radius: 4px;
        letter-spacing: 0.67px;
        cursor: pointer;
    }
}

