.process {
    &__row{
        display: flex;
        justify-content: space-around;
        flex-flow: row wrap;
    }

    &__photo{
        width: 328px;
        height: 225px;
        margin-bottom: 25px
    }
}