/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */



/*Обнуление*/
*{padding: 0;margin: 0;border: 0;}
*,*:before,*:after{-moz-box-sizing: border-box;-webkit-box-sizing: border-box;box-sizing: border-box;}
:focus,:active{outline: none;}
a:focus,a:active{outline: none;}
nav,footer,header,aside{display: block;}
html,body{height:100%;width:100%;font-size:100%;line-height:1;font-size:14px;-ms-text-size-adjust:100%;-moz-text-size-adjust:100%;-webkit-text-size-adjust:100%;}
input,button,textarea{font-family:inherit;}
input::-ms-clear{display: none;}
button{cursor: pointer;}
button::-moz-focus-inner{padding:0;border:0;}
a,a:visited{text-decoration: none;}
a:hover{text-decoration: none;}
ul li{list-style: none;}
img{vertical-align: top;}
h1,h2,h3,h4,h5,h6{font-size:inherit;font-weight: inherit;}
/*--------------------*/
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}
/*---------------------------------------------------*/

.material-icons {
  margin-right: 10px;
  align-items: center;
}

.container {
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
}


//<БЛОК>===========================================================================================================================

.header-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  &__title{
    font-size: 34px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 8px;
    position: relative;
    padding: 0px 0px 30px 0px;
    &:after {
      content: '';
      position: absolute;
      width: 150px;
      bottom: 0;
      height: 2px;
      left: 50%;
      background-color: #000;
      margin: 0px 0px 0px -80px;
    }

    @media (max-width:768px) {
      font-size: 25px;
  }

    @media (max-width:414px) {
      line-height: 30px;
      font-size: 23px;
    }

    @media (max-width:411px) {
      font-size: 20px;
    }

    @media (max-width:375px) {
      font-size: 19px;
    }

    @media (max-width:360px) {
        font-size: 18px;
    }

    @media (max-width:320px) {
      font-size: 18px;
      letter-spacing: 5.95px;
    }
  }

  &__subtitle{
    padding: 30px 0px 0px 0px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 5px;

    margin: 0px auto;

  }
}

.material-icons.md-48 {
  border-radius: 50%;
  color: #00829c;
  font-size: 60px; 
}


.material-icons.phone {
  color: #21aa5a;
}

// .material-icons.contact {
//   color: #990505;
// }



.soTest {
  max-width: 100%;
  padding: 100px 0 100px 0;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  text-align: justify;
      @media (max-width:617px) {
        font-size: 15px;
    }

        @media (max-width:450px) {
        font-size: 13px;
  }

              @media (max-width:355px) {
        font-size: 12px;
    }


}


.soflex1 div div
{

  background: #efefef;
  border: 1px solid #7F7F7F;
  padding: 9px 20px;
  @media (max-width:492px) {
    padding: 9px 10px;
  }

    @media (max-width:450px) {
    padding: 3px 5px;
  }

        @media (max-width:370px) {
    padding: 3px 1px;
    }

            @media (max-width:355px) {
    padding: 1px 1px;
    }

}


.soflex1
{
  flex-direction: row;
  display: flex;


  &__title {
      font-size: 20px;
      line-height: 26px;
      font-weight: 700;
      text-align: justify;

      @media (max-width:617px) {
        font-size: 16px;
    }

          @media (max-width:492px) {
        font-size: 15px;
    }

      @media (max-width:450px) {
        font-size: 12px;
    }

                  @media (max-width:355px) {
        font-size: 11px;
    }

                      @media (max-width:318px) {
        font-size: 9px;
    }
  }
}