.servis-detail {
    padding-top: 70px;

    &__title {
        display: flex;
        justify-content: center;
        line-height: 40px;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.89px;
        margin-bottom: 50px;

        @media (max-width:768px) {
            line-height: 30px;
            font-size: 15.5px;
        }

        @media (max-width:414px) {
            text-align: center;
            line-height: 30px;
            font-size: 15px;
        }

    }

    &__modal {
        text-align: center;
        margin-bottom: 50px;
    }

    &__table {
        display: flex;
        justify-content: center;
        margin: -80px 0 -80px 0;
    }

    &__block {
        display: flex;
        justify-content: space-around;
        flex-flow: row wrap;
        &__left {
            &__photo {
                width: 200px;
                height: 200px;
                margin: 20px 0 20px 0;
            }

            &__text {
                font-size: 22px;
                font-weight: 700;
                text-align: justify;
                margin-bottom: 20px;
            }
        }

        &__right {
            margin-top: 150px;
            &__header {
            font-size: 27px;
            line-height: 26px;
            font-weight: 700;
            text-align: justify;
            margin-bottom: 20px;
        }

        &__text {
            font-size: 20px;
            font-weight: 400;
            text-align: justify;
            line-height: 30px;
            margin-bottom: 20px;
        }
        }
    }
}