.rent-car {
    padding-top: 70px;

    &__header {
        height: 500px;
        background: url(../../../../../img/servis/rent-car/carpromo.JPG) center (center/cover) no-repeat;
    }

    &__body {
        &__title {
            display: flex;
            justify-content: center;
            text-align: center;
            font-weight: 700;
            font-size: 23px;
            text-transform: uppercase;
            letter-spacing: 3.15px;
            position: relative;
            padding: 50px 0px 20px 0px;
            &:after {
              content: '';
              position: absolute;
              width: 150px;
              bottom: 0;
              height: 2px;
              left: 50%;
              background-color: #000;
              margin: 0px 0px 0px -80px;
              }
        }



    }

    &__row {
        display: flex;
        justify-content: space-around;
        flex-flow: row wrap;
        margin-top: 30px;
    }

    &__block {
        height: 200px;
        width: 100%;
        // border: 2px solid black;
        display: flex;
        justify-content: flex-start;
        flex-flow: row wrap;
        align-items: center;
        text-align: center;
        @media (max-width:620px) {
            height: 130px;
            margin-bottom: 10px;
          }
        @media (max-width:450px) {
            height: 120px;
            margin-bottom: 10px;
          }
        
    }

    &__text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &__title {
            margin-bottom: 10px;
            font-weight: 700;
            font-size: 20px;
            text-transform: uppercase;
            letter-spacing: 1.15px;
            @media (max-width:620px) {
                font-size: 14px;
              }

              @media (max-width:430px) {
                font-size: 14px;
              }

              @media (max-width:375px) {
                    letter-spacing: 0px;
                font-size: 14px;
              }

              @media (max-width:320px) {
                letter-spacing: 0px;
                font-size: 13px;
              }
        }

        &__subtitle {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-weight: 500;
            font-size: 17px;
            line-height: 25px;
            letter-spacing: 1.15px;

            @media (max-width:620px) {
                font-size: 16px;
              }

              @media (max-width:510px) {
                font-size: 13px;
              }

              @media (max-width:420px) {
                font-size: 12px;
              }

              @media (max-width:400px) {
                letter-spacing: 0px;
                font-size: 12px;
              }

              
              @media (max-width:320px) {
                letter-spacing: 0px;
                font-size: 11.5px;
              }
        }
    }
}

.rent-car__img {
    width: 250px;
    height: 200px;
    margin-right: 10px;

    @media (max-width:620px) {
        width: 150px;
        height: 100px;
      }

      @media (max-width:430px) {
        width: 130px;
        height: 80px;
      }

      @media (max-width:375px) {
        width: 110px;
        height: 70px;
      }

      @media (max-width:320px) {
        width: 90px;
        height: 70px;
      }
}