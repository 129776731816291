.mainblock {
    height: 500px;
    background: url(../../../img/promo/block.jpg) center (center/cover) no-repeat;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__content {
        position: relative;
        margin: 160px 0 200px 0;

    }

    &__text {
        color: black;
        position: relative;
        padding: 0px 0px 45px 0px;
        margin: 30px 0;
        &:after {
            content: '';
            position: absolute;
            width: 150px;
            bottom: 0;
            height: 2px;
            left: 50%;
            background-color: #000;
            margin: 0px 0px 0px -80px;
        }
    }

    &__title {
        font-weight: 900;
        font-size: 34px;
        line-height: 60px;
        text-transform: uppercase;
        letter-spacing: 5.43px;

        @media (max-width:1024px) {
            font-size: 20px;
            line-height: 40px;
        }

        @media (max-width:414px) {
            font-size: 20px;
            line-height: 20px;
            margin-bottom: 20px;
        }
    }

    &__subtitle {
        font-weight: 400;
        font-size: 20px;
        text-transform: uppercase;
        letter-spacing: 3.15px;

        @media (max-width:1036px) {
            font-size: 25px;
        }

        @media (max-width:1024px) {
            font-size: 17px;
        }

        @media (max-width:414px) {
            font-size: 15px;
            line-height: 20px;
        }
    }

    &__phone {
        padding-top: 30px;
        font-weight: 400;
        font-size: 26px;
        text-transform: uppercase;
        letter-spacing: 3.15px;

        &__call {
            font-weight: 700;
            color: rgb(0, 0, 0);
            text-decoration: none;
            text-transform: uppercase;

            @media (max-width:412px) {
                font-size: 18px;
            }
        }
    }

    &__btn {
        background-color: #303030;
        color: white;
        text-transform: uppercase;
        padding: 12px 20px;
        border: none;
        border-radius: 4px;
        letter-spacing: 0.67px;
        cursor: pointer;
    }
}