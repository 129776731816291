.header {
    display: flex;
    justify-content: space-around;
    height: 70px;
    width: 100%;
    background-color: #282c34;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    position: fixed;
    z-index: 1;

      @media (max-width:460px) {
        justify-content: flex-start;
      }
}


.header-logo {
    display: flex;
    align-items: center;
    &__img {
        width: 70px;
        height: 70px;
    }
    &__contacts {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-end;
        height: 70px;
        padding: 10px 10px;



        &__title {
            color: #BBBBBB;
            text-decoration: none;
            font-weight: 500;
            font-size: 16px;
            text-transform: uppercase;

            @media (max-width:460px) {
                justify-content: flex-start;
              }
          
              @media (max-width:411px) {
                font-size: 17px;
              }
          
              @media (max-width:375px) {
                font-size: 15px;
              }
          
              @media (max-width:360px) {
                  font-size: 14px;
              }
          
              @media (max-width:320px) {
                font-size: 12px;
              }
        }
 
        &__phone {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #BBBBBB;
            font-weight: 500;
            font-size: 17px;
            text-decoration: none;
            text-transform: uppercase;
              @media (max-width:360px) {
                  font-size: 14px;
              }
          
              @media (max-width:320px) {
                font-size: 12px;
              }
        }
    }
}



.header > .header-btn {
    display: none;
}



.header > #header-check {
    display: none;
}

@media (max-width:1183px) {
    .header > .header-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
}
.header > .header-btn > label {
    display: inline-block;
    width: 70px;
    height: 70px;
    padding: 23px;

    @media (max-width:375px) {
        width: 50px;
        height: 50px;
        padding: 20px;
      }
}
.header > .header-btn > label:hover {
    background-color: rgba(0, 0, 0, 0.3);
}
.header > .header-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid #f3eded;
    cursor:pointer;
}
.header > .menu {
    position: absolute;
    display: block;
    width: 100%;
    background-color: #32363f;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 70px;
    left: 0px;
}
.header > .menu > a {
    display: block;
    width: 100%;
}
.header > #header-check:not(:checked) + .menu {
    height: 0px;
}
.header > #header-check:checked + .menu {
    text-align: left;
    font-weight: 500;
    text-transform: uppercase;
    height: 330px;
    overflow-y: auto;
}
}

.menu {
    &__item {
            float: left;
            color: #BBBBBB;
            text-align: center;
            padding: 26px 16px;
            text-decoration: none;
            font-size: 17px;
            @media (max-width:1400px) {
                font-size: 15px;
            }
            @media (max-width:1290px) {
                font-size: 14px;
            }
            @media (max-width:1240px) {
                font-size: 13px;
            }
            @media (max-width:768px) {
                display: none;
            }
            &:hover {
                background-color: #181818;
                color: #f2f2f2;
            }

            &:active {
                background-color: #303030;
                color: white;
              }
    }
}